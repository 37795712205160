<template>
  <div>
    <cytoscape :config="config" :preConfig="preConfig" :afterCreated="afterCreated">
      <cy-element
          v-for="def in elements"
          :key="`${def.data.id}`"
          :definition="def"
      />
    </cytoscape>
    <b-alert v-for="(step, index) in steps" :key="index" variant="primary" show >
      <strong v-html="step.message"></strong>
      <span v-if="step.links.length > 0"> and found the following links:</span>
        <ul>
          <li v-for="(link, lindex) in step.links" :key="lindex">
            <a :href="link.url">{{link.label}}</a>
          </li>
        </ul>
      <b-button @click="toggle(step)" :pressed.sync="step.selected">Highlight</b-button>
    </b-alert>
    <b-alert variant="danger" show>
      {{single.message}}
    </b-alert>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {Getters, Mutations} from "@/store/conflict/type"
import fcose from 'cytoscape-fcose';

export default {
  name: "ConflictVisual",
  data() {
    return {
      button: [{state: false}],
      config: {
        style: [
          {
            selector: 'label',
            style: {
            }
          },
          {
            selector: 'node',
            style: {
              'background-color': '#666',
              'label': 'data(label)',
              "font-size": "10px",

            }
          }, {
            selector: function (el) {
              if( typeof el.data().class !== "undefined") {
                return el.data().class.includes('error')
              }
              return false;
            },
            css: {
              'background-color': 'purple',
            }
          },{
            selector: function (el) {
              if( typeof el.data().class !== "undefined") {
                return el.data().class.includes('collected')
              }
              return false
            },
            css: {
              'background-color': 'yellow',
            }
          },{
            selector: function (el) {
              if( typeof el.data().class !== "undefined") {
                return el.data().class.includes('highlight')
              }
              return false
            },
            css: {
              'background-color': 'red',
            }
          },
          {
            selector: 'edge',
            style: {
              'width': 2,
              'line-color': 'rgba(0,0,0,0.5)',
              'target-arrow-color': '#ccc',
              'target-arrow-shape': 'triangle'
            }
          },
          {
            selector: ':parent',
            style: {
              'background-opacity': 0.333,
              'border-color': '#2B65EC',
              'background-color': '#2B65EC'
            }
          },
        ],
        layout: {
          name: 'fcose',
          fit: false,
          nodeDimensionsIncludeLabels: true,
          tilingPaddingVertical: 1,
          // Represents the amount of the horizontal space to put between the zero degree members during the tiling operation(can also be a function)
          tilingPaddingHorizontal: 1,
          // Node repulsion (non overlapping) multiplier
          nodeRepulsion: node => 1,
          // Ideal edge (non nested) length
          idealEdgeLength: edge => 1,
          // Divisor to compute edge forces
          edgeElasticity: edge => 0.45,
          nodeSeparation: 1


        },
      }
    }
  },
  computed: {
    ...mapGetters('conflict', {single: Getters.SINGLE}),
    elements() {
      // concat nodes and edges for cytoscape
      return this.single.nodes.concat(this.single.edges)
    },
    steps() {
      // calculate steps
      return this.single.steps
    }
  },
  methods: {
    ...mapMutations('conflict', {'highlightStore': Mutations.HIGHLIGHT, maskStore: Mutations.MASK}),
    preConfig(cytoscape) {
      // cytoscape: this is the cytoscape constructor
      cytoscape.use(fcose);
    },
    afterCreated: function(cy) {
      this.$nextTick(() => {
        cy.layout(this.config.layout).run();
        cy.center()
      });
    },
    toggle(step) {
      if(!step.selected) {
        this.maskStore(step);
      } else {
        this.highlightStore(step);
      }
    }
  }
}
</script>

<style scoped>

</style>
