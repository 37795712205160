<template>

  <div id="app">
    <Navbar/>
    <div class="content">
      <Error></Error>
      <router-view/>
    </div>
  </div>
</template>

<script>
  import Navbar from "@/components/Navbar";
  import {mapActions} from 'vuex';
  import Error from "@/components/Error";

  export default {
    components: {Error, Navbar},
    methods: {
      ...mapActions('provider', ['list']),
    },
    mounted() {
      this.list();
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  .content {
    margin-top: 90px
  }
</style>
