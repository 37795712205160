<template>
    <div>
    <transition-group name="fade" mode="out-in">
        <div v-for="(ml, index) in list" :key="index" class="mb-4">
            <b-card no-body header-bg-variant="primary" border-variant="primary" header-text-variant="light" class="mb-0 rounded-top rounded-bottom-0 card-master">
                <template v-slot:header>
                    <div class="action-header">
                        <h6 class="mb-0">Concordance #{{ml.base.id}}</h6>
                    </div>
                </template>

                <b-list-group flush>
                    <b-list-group-item v-for="(person, key) in ml.base.persons" :key="key">
                        <div class="d-flex">
                            <PersonInfoRow :person="person" class="flex-grow-1"></PersonInfoRow>
                            <div class="action"><b-form-checkbox switch checked="true" disabled></b-form-checkbox></div>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
            <b-card no-body
                    class="mb-0 rounded-0 card-slave"
                    border-variant="primary"
                    v-for="(proposal, key) in ml.proposals" :key="key">
                <template v-slot:header>
                    <div class="action-header">
                        <h6 class="mb-0">Concordance #{{proposal.id}}</h6>
                        <b-form-checkbox switch v-model="proposal.match" @change="changeProposal(proposal, $event)"></b-form-checkbox>
                    </div>
                </template>

                <b-list-group flush>
                    <b-list-group-item v-for="(person, key) in proposal.persons" :key="key">
                        <div class="d-flex justify-content-between group-row">
                            <PersonInfoRow :person="person" class="flex-grow-1"></PersonInfoRow>
                            <div class="action"><b-form-checkbox switch v-model="person.match" :disabled="true"></b-form-checkbox></div>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
          <div class="d-flex justify-content-end list-group-item action-footer">
            <b-btn-group>
              <b-button @click="preSave(ml)">Save</b-button>
            </b-btn-group>
          </div>
        </div>
    </transition-group>
</div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations} from 'vuex';
    import PersonInfoRow from "@/components/PersonInfoRow";
  export default {
    name: "ProposalList",
    components: {PersonInfoRow},
    computed: {
      ...mapGetters('proposal', ['list']),
      proposals() {
        return this.list.map((p) => {
          p.base.match = true;
          p.base.persons.forEach((person) => person.match = true);
        })
      }
    },
    methods: {
      ...mapActions('proposal', ['accept', 'reject']),
      ...mapActions('concordance', ['update', 'merge']),
      ...mapMutations('concordance', ['edit']),
      ...mapMutations('proposal', ['remove']),
      preSave(proposal) {
        // merge concordances
        const matches = proposal.proposals.filter((c) => c.match);
        // merge two concordances
        if(matches.length > 0) {
          this.edit(proposal.base);
          for (const c of matches) {
            this.edit(c)
          }
          this.merge()
        }
        // save all refused
        const refused = proposal.proposals.filter((c) => !c.match);
        if(refused.length > 0) {
          // save in base
          refused.push(proposal.base);
          for(const r of refused) {
            r.refused_matches.push(...refused.filter(c => c.id !== r.id).map(c => c.id));
            this.update(r);
          }
        }
        this.remove(proposal);
      },
      changeProposal(proposal, $event) {
        proposal.persons.forEach((p) => {
          p.match = $event;
        });
        this.$forceUpdate();
      },
    }
  }
</script>

<style scoped lang="scss">
    .action-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .action-footer {
      border-left: 1px solid var(--primary);
      border-right: 1px solid var(--primary);
      border-bottom: 1px solid var(--primary);
    }
    .rounded-bottom-0 {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-master {
        border-bottom: 0;
    }

    .card-slave {
        border-bottom: 0;
        border-top: 0;

        &:last-child {
            border-bottom: 1px solid var(--primary);
        }
    }
</style>
