<template>
  <div>
    <div class="container-fluid" v-if="!loading">
      <ConflictVisual/>
    </div>
    <div class="container-fluid d-flex justify-content-center mb-3" v-if="loading">
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Getters, Actions} from "@/store/conflict/type";
import ConflictVisual from "@/components/ConflictVisual"
export default {
  name: "Conflict",
  components: { ConflictVisual},
  mounted() {
    const id = this.$route.params.id;
    this.single(id);
  },
  methods: {
    ...mapActions('conflict', [Actions.SINGLE]),
  },
  computed: {
    ...mapGetters('conflict', {loading: Getters.LOADING})
  }
}
</script>

<style scoped>

</style>
