<template>
  <div class="conflict">
    <div class="container-fluid">
      <ConflictList/>
      <Pagination :get="list" ref="pagination"></Pagination>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import ConflictList from "@/components/ConflictList";
import Pagination from "@/components/Pagination";
export default {
  name: "ConflictOverview",
  components: {ConflictList, Pagination},
  mounted() {
    this.$refs.pagination.change();
  },
  methods: {
    ...mapActions('conflict', ['list']),
  }
}
</script>

<style scoped>

</style>
