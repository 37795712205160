<template>
    <div class="form-container mb-4" :class="className">
        <div class="p-4 border-dark border">
        <h1>{{header}}</h1>
        <slot></slot>
        <slot name="action"></slot>
        </div>
    </div>
</template>

<script>
  export default {
    name: "FormContainer",
    props: {
      header: {
        type: String
      },
      className: {
        type: String
      }
    }
  }
</script>

<style scoped lang="scss">
    .form-container {
        background: #eee;
    }
</style>
