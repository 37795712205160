export const Actions = {
    LIST: 'list',
};

export const Getters = {
    LIST: 'list',
};

export const Mutations = {
    LIST: 'list',
};
