<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="primary" fixed="top">
            <b-navbar-brand href="/">
              <img src="@/assets/logo-metagrid.png" alt="Logo Metagrid inverted" class="logo">
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item to="/">
                      <b-icon icon="search"></b-icon> Search
                    </b-nav-item>
                    <b-nav-item to="/matching">
                      <b-icon icon="shuffle"></b-icon> Match
                    </b-nav-item>
                    <b-nav-item to="/conflict">
                      <b-icon icon="box"></b-icon> Data insights <b-badge pill variant="warning">Alpha</b-badge>
                    </b-nav-item>
                  <b-nav-item @click="$keycloak.logoutFn"  v-if="$keycloak.authenticated">
                      <b-icon icon="power"></b-icon> Logout
                  </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
  export default {
    name: "Navbar",
  }
</script>

<style scoped lang="scss">
.logo {
  max-height: 40px;
  width: auto;
}
</style>
