export const Actions = {
    LIST: 'list',
    TEST: 'test',
    SINGLE: 'single',
    NEW: 'new',
};

export const Getters = {
    LIST: 'list',
    LOADING: 'loading',
    SINGLE: 'single',
};

export const Mutations = {
    LIST: 'list',
    START_LOAD: 'startLoad',
    FINISH_LOAD: 'finishLoad',
    INSERT: 'insert',
    SET: 'set',
    NEW: 'new',
};
