import {api, MatchProposal, Match, MetagridError} from '@/store';
import { Module} from "vuex"
import {Actions, Getters, Mutations} from '@/store/proposal/type';
import {ErrorType} from '@/store/error';

export interface TmpPagination {
    from: number;
    size: number;
}
interface ProposalState {
    // id of the match associated with the matching options
    id: string;
    // state to indicate if we are loading
    loading: boolean;
    // a list of proposals
    list: MatchProposal[];
}

const proposal: Module<ProposalState, any> = {
    namespaced: true,
    state: {
        id: '',
        loading: false,
        list: [],
    },
    mutations: {
        /**
         * Set the list of proposals
         * @param state
         * @param list
         */
        [Mutations.LIST]: (state, list) => {
            state.list = list;
        },

        /**
         * Clear a list of proposals
         * @param state
         */
        [Mutations.CLEAR]: (state) => {
            state.list = [];
        },

        /**
         * init loading
         * @param state
         */
        [Mutations.START_LOAD]: (state) => {
            state.loading = true;
        },

        /**
         * finish loading
         * @param state
         */
        [Mutations.FINISH_LOAD]: (state) => {
            state.loading = false;
        },

        /**
         * Set the id of the match to fetch
         * @param state
         * @param id
         */
        [Mutations.ID]: (state, id) => {
            state.id = id;
        },

        /**
         * Remove a single proposal form the list
         * @param state
         * @param proposal
         */
        [Mutations.REMOVE]: (state, proposal: MatchProposal) => {
            const i = state.list.findIndex(p => p === proposal)
            state.list.splice(i, 1);
        }
    },
    getters: {
        /**
         * Get the list of proposals
         * @param state
         */
        [Getters.LIST]: state => {
            return state.list;
        },

        /**
         * Get the state of loading
         * @param state
         */
        [Getters.LOADING]: state => {
            return state.loading;
        },
    },
    actions: {
        /**
         * get a list of pre-generated proposals
         * @param commit
         * @param state
         * @param pagination
         * @param query
         * @return number
         */
        async [Actions.LIST]({commit, state}, pagination: TmpPagination) {
            commit(Mutations.START_LOAD);
            if(typeof pagination === "undefined") {
                pagination = {
                    size: 20,
                    from: 0,
                };
            }
            const params: string[] = [];
            params.push(`from=${pagination.from}`);
            params.push(`size=${pagination.size}`);
            const proposals = await fetch(`${api}/v3/matches/${state.id}/proposals?${params.join("&")}`)
                .then(r => {
                    if (r.status >= 200 && r.status <= 299) {
                        return r.json();
                    } else {
                        throw Error(r.statusText);
                    }
                })
                .catch((err) => {
                    const metagridError: MetagridError = {
                        title: "Network error",
                        message: 'Can\'t fetch proposals from server.',
                        type: ErrorType.error
                    }
                    commit('error/add', metagridError, { root: true });
                    throw err;
                });
            commit(Mutations.FINISH_LOAD);
            commit(Mutations.LIST, proposals.proposals);
            // todo: return intTotal
            return proposals.meta.total;
        },

        /**
         * Trigger a test run for the matching
         * todo: how do we handle pagination?
         * @param commit
         * @param state
         * @param match
         */
        async [Actions.TEST]({commit}, match: Match) {
            commit(Mutations.START_LOAD);
            const proposals = await fetch(`${api}/v3/matches/test`,
                {
                    method: 'POST',
                    body: JSON.stringify({match}),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                })
                .then(r => {
                    if (r.status >= 200 && r.status <= 299) {
                        return r.json();
                    } else {
                        throw Error(r.statusText);
                    }
                })
                .catch((err) => {
                    const metagridError: MetagridError = {
                        title: "Network error",
                        message: 'Can\'t test the matching.',
                        type: ErrorType.error
                    }
                    commit('error/add', metagridError, { root: true });
                    throw err;
                });
            commit(Mutations.LIST, proposals.proposals);
            commit(Mutations.FINISH_LOAD);
        },
    }
};

export default proposal

