<template>
  <div>
    <b-alert variant="primary" :show="list.length === 0">
      No data for your {{ $keycloak.tokenParsed.provider }}. Please check back later.
    </b-alert>
    <b-table
        id="my-table"
        :busy.sync="loading"
        :items="list"
        :fields="fields"
        v-if="list.length > 0">
      <template v-slot:cell(id)="id">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <RouterLink :to="`/conflict/${id.value}`">{{id.value}}</RouterLink>
      </template>
      <template v-slot:cell(created_at)="created_at">
        <!-- `data.value` is the value after formatted by the Formatter -->
        {{created_at.value | date}}
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "ConflictList",
  data () {
    return {
      fields:  [
        {
          key: 'id',
          sortable: false
        },
        {
          key: 'created_at',
          sortable: true
        },
      ]
    }
  },
  computed: {
    ...mapGetters('conflict', ['list', 'loading'])
  },
  filters: {
    date: function(d) {
      return d.slice(0, 10)
    }
  }
}
</script>

<style scoped>

</style>
