<template><div></div></template>
<script>
import {mapGetters} from 'vuex'
export default {
  name: "Error",
  watch: {
    'getLatest': function (value) {
      this.makeToast(value)
    }
  },
  computed: {
      ...mapGetters('error', ['getLatest'])
  },
  methods: {
    /***
     * Make a bootsrap toast to inform about global errors
     * @param error {MetagridError}
     **/
    makeToast(error) {
      this.$bvToast.toast(error.message, {
        title: error.title,
        variant: this.toVariant(error.type),
        autoHideDelay: 8000,
        appendToast: true,
      });
    },
    /**
     * Transform a ErrorType to a bootstrap variant
     * @param type {string}
     * @return {string|*}
     */
    toVariant(type) {
      switch (type) {
        case 'error':
          return 'danger';
        default:
          return type;
      }
    }
  }
}
</script>
