import {Module} from 'vuex';
import {Getters, Mutations} from '@/store/error/type';
import {MetagridError} from '@/store';

// State of the error
export interface ErrorState {
    errors: MetagridError[];
}

// SearchQueryKeys
export enum ErrorType {
    info = 'info',
    warn = 'warn',
    error = 'error',
}

const error: Module<ErrorState, any> = {
    namespaced: true,
    state: {
        errors: []
    },
    getters: {
        /**
         * Get all errors as a list
         * @param state
         */
        [Getters.LIST]: state => {
            return state.errors
        },
        /**
         * Just returns the newest error
         * @param state
         */
        [Getters.GET_LATEST]: state => {
            return state.errors[state.errors.length -1]
        },
    },
    mutations: {
        /**
         * Add an error to the stack
         * @param state
         * @param error
         */
        [Mutations.ADD]: (state, error: MetagridError) => {
            state.errors.push(error)
        },
    }
}

export default error;
