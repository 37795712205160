<template>
  <div class="search">
    <div class="container-fluid">
      <SearchForm @search="preSearch"/>
    </div>
    <div class="container-fluid" v-show="!loading">
      <ResultList/>
      <Pagination :get="list" ref="pagination"></Pagination>
    </div>
    <div class="container-fluid d-flex justify-content-center mb-3" v-if="loading">
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>

    <div class="edit-box">
      <div class="container-fluid">
        <EditForm  ref="editform"/>
      </div>
    </div>
    <div class="backdrop" @click="clickBackdrop"/>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchForm from '@/components/SearchForm.vue'
import ResultList from '@/components/ResultList.vue'
import EditForm from "@/components/EditForm";
import Pagination from "@/components/Pagination";
import {mapActions, mapGetters} from 'vuex';
import {Getters} from "@/store/concordance/type";
export default {
  name: 'Search',
  mounted() {
    this.$refs.pagination.change();
  },
  methods: {
    /**
     * toggle edit form if user clicks on backdrop
     * hint: this calls the method on the child component
     */
    clickBackdrop() {
      this.$refs.editform.toggle();
    },
    ...mapActions('concordance', ['list']),
    preSearch() {
      this.$refs.pagination.change(1);
    }
  },
  computed: {
    ...mapGetters('concordance', {loading: Getters.LOADING})
  },
  components: {
    EditForm,
    ResultList,
    SearchForm,
    Pagination
  }
}
</script>
<style lang="scss">
  .edit-box {
    position: fixed;
    top: 100%;
    bottom: auto;
    width: 100%;
    z-index: 100;
    transition: top 300ms ease-in-out;

    .edit-box-open & {
      top: auto;
      bottom: 0;
    }
  }

  .backdrop {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  // backdrop animations
  .edit-box-close .backdrop,
  .edit-box-open .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.25;
    background: #000;
    z-index: 10;
  }

  .edit-box-close .backdrop {
    opacity: 0;
  }
</style>
