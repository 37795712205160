import Vue from 'vue'
import VueRouter from 'vue-router'
import Search from '../views/Search.vue'
import Proposal from '../views/Proposal.vue'
import MatchOverview from '../views/MatchOverview.vue'
import ConflictOverview from '../views/ConflictOverview.vue'
import Conflict from '../views/Conflict.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Search',
    component: Search
  },
  {
    path: '/matching',
    name: 'MatchOverview',
    component: MatchOverview
  },
  {
    path: '/matching/:id',
    name: 'Proposal',
    component: Proposal
  },
  {
    path: '/conflict',
    name: 'ConflictOverview',
    component: ConflictOverview
  },
  {
    path: '/conflict/:id',
    name: 'Conflict',
    component: Conflict
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
