import {Module} from 'vuex';
import {api, Match, MetagridError, Pagination} from '@/store';
import {Actions, Getters, Mutations} from '@/store/match/type';
import {ErrorType} from '@/store/error';

interface MatchState {
    loading: boolean;
        list: Match[];
        single: Match;
}
const match: Module<MatchState, any> = {
    namespaced: true,
    state: {
        loading: false,
        list: [],
        single: {
            id: '',
            name: '',
            type: '',
            createdAt: '',
            updatedAt: '',
            parameters: [],
        },
    },
    mutations: {
        /**
         * Insert a match at the beginning of the matchList
         * @param state
         * @param match
         */
        [Mutations.INSERT](state, match: Match) {
            state.list.unshift(match);
        },

        /**
         * Override the matches data
         * @param state
         * @param matches
         */
        [Mutations.LIST](state, matches: Match[]) {
            state.list = matches;
        },

        /**
         * Set a single match
         * @param state
         * @param match
         */
        [Mutations.SET](state, match: Match) {
            state.single = match;
        },

        /**
         * Create a new match
         * @param state
         */
        [Mutations.NEW](state) {
            state.single =  {
                id: 'new',
                name: 'New matching',
                type: '',
                createdAt: '',
                updatedAt: '',
                parameters: [],
            }
        },
        /**
         * init loading
         * @param state
         */
        [Mutations.START_LOAD]: (state) => {
            state.loading = true;
        },

        /**
         * finish loading
         * @param state
         */
        [Mutations.FINISH_LOAD]: (state) => {
            state.loading = false;
        },
    },
    getters: {
        /**
         * Get a list of all ran and running matches
         * @param state
         */
        [Getters.LIST]: state => {
            return state.list;
        },
        /**
         * Get a selected match
         * @param state
         */
        [Getters.SINGLE]: state => {
            return state.single;
        },
        /**
         * Get the state of loading
         * @param state
         */
        [Getters.LOADING]: state => {
            return state.loading;
        },

    },
    actions: {
        /**
         * Get a list of matches form the server
         * @param commit
         * @param state
         * @param pagination
         */
        async [Actions.LIST]({commit}, pagination: Pagination) {
            commit(Mutations.START_LOAD);
            const m = await fetch(`${api}/v3/matches`)
                .then(r => {
                    if (r.status >= 200 && r.status <= 299) {
                        return r.json();
                    } else {
                        throw Error(r.statusText);
                    }
                }).catch((err) => {
                    const metagridError: MetagridError = {
                        title: "Network error",
                        message: 'Can\'t fetch list of matchings.',
                        type: ErrorType.error
                    }
                    commit('error/add', metagridError, { root: true });
                    throw err;
                });
            commit(Mutations.LIST, m.matches);
            commit(Mutations.FINISH_LOAD);
            return m.meta.total;
        },

        /**
         * Get a single match
         * @param commit
         * @param state
         * @param id
         */
        async [Actions.SINGLE]({commit, state}, id: string) {
            commit(Mutations.START_LOAD);
            // if we wanna create a new one
            // todo: we dont need this here
            if(id === 'new') {
                commit(Mutations.NEW);
                return;
            }
            let match = state.list.find((m: any) => m.id === id);
            if(typeof match === 'undefined') {
                match = await fetch(`${api}/v3/matches/${id}`)
                    .then(r => {
                        if (r.status >= 200 && r.status <= 299) {
                            return r.json();
                        } else {
                            throw Error(r.statusText);
                        }
                    }).catch((err) => {
                        const metagridError: MetagridError = {
                            title: "Network error",
                            message: 'Can\'t fetch matching from server.',
                            type: ErrorType.error
                        }
                        commit('error/add', metagridError, { root: true });
                        throw err;
                    });
            }
            commit(Mutations.SET, match);
            commit(Mutations.FINISH_LOAD);
        },
        async [Actions.NEW]({commit}, match: Match) {
            commit(Mutations.START_LOAD);
            const m = await fetch(`${api}/v3/matches`,
                {
                    method: 'POST',
                    body: JSON.stringify({match}),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                })
                .then(r => {
                    if (r.status >= 200 && r.status <= 299) {
                        return r.json();
                    } else {
                        throw Error(r.statusText);
                    }
                }).catch((err) => {
                    const metagridError: MetagridError = {
                        title: "Network error",
                        message: 'Can\'t save matching.',
                        type: ErrorType.error
                    }
                    commit('error/add', metagridError, { root: true });
                    throw err;
                });
            commit(Mutations.INSERT, m);
            commit(Mutations.FINISH_LOAD);
            return m;
        },

    }
};

export default match;
