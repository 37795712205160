/**
 * This file exports a check function to check if an edited concordance can be saved as expected
 */
import {Concordance, Person} from '@/store';

// interface for check classes. Its possible to just compose more classes together
interface Check {
    fn: CheckFunction;
}

// check function type
type CheckFunction = (concordances: ConcordanceValidation[]) => ConcordanceValidation[];

export interface ConcordanceValidation extends Concordance{
    persons: PersonValidation[];
}

export interface PersonValidation extends Person {
    error: boolean;
    errorMsg: string;
}

// check class. This class runs all the checks
class Checks {
    /**
     * checks
     */
    private checks: Check[] = [];

    /**
     * Run all the checks
     * @param concordances
     */
    public run(concordances: Concordance[]) {
        // cast type
        let cons = concordances as ConcordanceValidation[];
        this.checks.forEach((c) => {
          cons = c.fn(cons)
        });
        return cons;
    }

    /**
     * Check if a concordanceValidations as an error. If so, we cant save
     * @param concordances
     */
    public hasError(concordances: ConcordanceValidation[]): boolean {
        for(let i = 0; i < concordances.length; i++) {
            for(let x = 0; x < concordances[i].persons.length; x++) {
                if(concordances[i].persons[x].error) {
                    return true;
                }
            }
        }
        return false;
    }
    /**
     * Set check functions
     * @param check
     */
    public setCheck(check: Check) {
        this.checks.push(check);
    }
}

/**
 * This check looks for the same providers
 */
class SameProvider implements Check {
    /**
     * checked providers
     */
    private providers: {[key: string]: {concordance: number; person: number}} = {};

    /**
     * Function we call for the checks
     * @param concordances
     */
    fn(concordances: ConcordanceValidation[]): ConcordanceValidation[] {
        this.providers = {};
        for(let i = 0; i < concordances.length; i++) {
            for(let x = 0; x < concordances[i].persons.length; x++) {
                if(typeof this.providers[concordances[i].persons[x].provider] !== "undefined") {
                    const start = this.providers[concordances[i].persons[x].provider];
                    concordances[i].persons[x].error = true;
                    concordances[i].persons[x].errorMsg = 'Same provider';
                    concordances[start.concordance].persons[start.person].error = true;
                    concordances[start.concordance].persons[start.person].errorMsg = 'Same provider';
                } else {
                    concordances[i].persons[x].error = false;
                }
                this.providers[concordances[i].persons[x].provider] = {concordance: i, person: x}
            }
        }
        return concordances;
    }
}

// init new check class
const checks = new Checks();
// add same provider check
checks.setCheck(new SameProvider());
export default checks;

