import {Commit, Module} from 'vuex';
import {api, Conflict, MetagridError, Node, Step} from '@/store';
import {Actions, Getters, Mutations} from '@/store/conflict/type';
import {ErrorType} from '@/store/error';
import {TmpPagination} from "@/store/proposal";

interface ConflictState {
    loading: boolean;
    list: Conflict[];
    single: Conflict;
}

const conflict: Module<ConflictState, any> = {
    namespaced: true,
    state: {
        loading: false,
        list: [],
        single: {
            nodes: [],
            edges: [],
            steps: []
        },
    },
    mutations: {
        /**
         * Override the conflicts data
         * @param state
         * @param conflict
         */
        [Mutations.LIST](state: ConflictState, conflict: Conflict[]): void {
            state.list = conflict;
        },

        /**
         * Set a single conflict
         * @param state
         * @param conflict
         */
        [Mutations.SET](state: ConflictState, conflict: Conflict) : void {
            state.single = conflict;
        },

        [Mutations.HIGHLIGHT](state: ConflictState, step: Step): void {
            // select step
            state.single.steps.map((s) => {
                if(s === step) {
                    s.selected = true;
                }
            });

            // highlight the nodes
            const selectedNodes: string[] = []
            step.links.forEach((l) => {
                selectedNodes.push(l.label)
            })
            selectedNodes.push(step.main_person.label)
            state.single.nodes.forEach((n: Node) => {
                if(selectedNodes.includes(n.data.label)) {
                    if(!n.data.class.includes('highlight')) {
                        n.data.class.push('highlight');
                    }
                }
            })
        },

        [Mutations.MASK](state: ConflictState, step: Step): void {
            // mask step
            state.single.steps.map((s) => {
                if(s === step) {
                    s.selected = false;
                }
            });

            // masks the nodes again
            const selectedNodes: string[] = []
            step.links.forEach((l) => {
                selectedNodes.push(l.label)
            })
            selectedNodes.push(step.main_person.label)
            state.single.nodes.forEach((n: Node) => {
                if(selectedNodes.includes(n.data.label)) {
                    n.data.class.splice(n.data.class.indexOf('highlight'),1)
                }
                return n
            })
        },
        /**
         * init loading
         * @param state
         */
        [Mutations.START_LOAD]: (state: ConflictState) : void => {
            state.loading = true;
        },

        /**
         * finish loading
         * @param state
         */
        [Mutations.FINISH_LOAD]: (state: ConflictState): void => {
            state.loading = false;
        },
    },
    getters: {
        /**
         * Get a paginated list of conflicts
         * @param state
         */
        [Getters.LIST]: (state: ConflictState) : Conflict[] => {
            return state.list;
        },
        /**
         * Get the selected conflict
         * @param state
         */
        [Getters.SINGLE]: (state: ConflictState): Conflict => {
            return state.single;
        },
        /**
         * Get the state of loading
         * @param state
         */
        [Getters.LOADING]: (state: ConflictState) : boolean => {
            return state.loading;
        },

    },
    actions: {
        /**
         * Get a list of conflicts form the server
         * @param commit
         * @param state
         * @param pagination
         */
        async [Actions.LIST]({commit} : {commit: Commit}, pagination: TmpPagination): Promise<void> {
            if(typeof pagination === "undefined") {
                pagination = {
                    size: 20,
                    from: 0,
                };
            }
            commit(Mutations.START_LOAD);
            const m: any = await fetch(`${api}/v3/conflicts?from=${pagination.from}&size=${pagination.size}`)
                .then(r => {
                    if (r.status >= 200 && r.status <= 299) {
                        return r.json();
                    } else {
                        throw Error(r.statusText);
                    }
                }).catch((err) => {
                    const metagridError: MetagridError = {
                        title: "Network error",
                        message: 'Can\'t fetch list of conflicts.',
                        type: ErrorType.error
                    }
                    commit('error/add', metagridError, { root: true });
                    throw err;
                });
            commit(Mutations.LIST, m.conflicts);
            commit(Mutations.FINISH_LOAD);
            return m.meta.total;
        },

        /**
         * Get a single conflicts
         * @param commit
         * @param state
         * @param id
         */
        async [Actions.SINGLE]({commit}: {commit: Commit}, id: string): Promise<void> {
            commit(Mutations.START_LOAD);
            const conflict: Conflict = await fetch(`${api}/v3/conflicts/${id}`)
                .then(r => {
                    if (r.status >= 200 && r.status <= 299) {
                        return r.json();
                    } else {
                        throw Error(r.statusText);
                    }
                }).catch((err) => {
                    const metagridError: MetagridError = {
                        title: "Network error",
                        message: 'Can\'t fetch matching from server.',
                        type: ErrorType.error
                    }
                    commit('error/add', metagridError, { root: true });
                    throw err;
                });
            // Map state
            conflict.steps = conflict.steps.map((s) => {
                s.selected = false
                return s
            })
            commit(Mutations.SET, conflict);
            commit(Mutations.FINISH_LOAD);
        },
    }
};

export default conflict;
