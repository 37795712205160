import Vue from 'vue'
import Vuex from 'vuex'
import proposal from '@/store/proposal';
import concordance from '@/store/concordance';
import match from '@/store/match';
import provider from '@/store/provider';
import error, {ErrorType} from '@/store/error';
import conflict from "@/store/conflict";

Vue.use(Vuex);

// shortcut for api url
export const api = process.env.VUE_APP_API_URL;

// interface for a search query
export interface SearchQuery {
  query: string;
  length: number;
  provider: string;
  year1: number;
  year2: number;
}

// pagination interface
export interface Pagination {
  limit: number;
  current: number;
  total?: number;
}

// Person interface
export interface Person {
  url: string;
  identifier: string;
  provider: string;
  first_name: string;
  last_name: string;
  birth_date: string;
  death_date: string;
  updated_at: string;
  user: string;
}

// concordance interface
export interface Concordance {
  id: string;
  length: number;
  updated_at: string;
  user: string;
  persons: Person[];
  refused_matches: string[];
}

// provider detail interface
export interface ProviderDetail {
  name: string;
  short_description: string;
  long_description: string;
  lang: string;
  fallback: boolean;
}

// provider interface
export interface Provider  {
  id: number;
  slug: string;
  provider_details: ProviderDetail[];
}

// MatchParameter interface
export interface MatchParameter {
  field: string;
  operator: string;
  type: string;
  option1: string;
  option2: string;
}

// match interface
export interface Match {
  id: string;
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  parameters: MatchParameter[];
}

// proposals interface
export interface MatchProposal {
  base: Concordance;
  proposals: Concordance[];
}

// metagridError interface for global error handling
export interface MetagridError {
  title: string;
  message: string;
  type: ErrorType;
}

export interface Conflict {
  edges: Edge[]
  nodes: Node[]
  steps: Step[]
}

export interface Step {
  number: number
  message: string
  main_person: link
  links: link[]
  selected: boolean
}

export interface link {
  url: string
  label: string
}

export interface Edge {
  id: string
  data: EdgeData
}

export interface EdgeData {
  source: string
  target: string
}

export interface  Node {
  id: string
  data: NodeData
}

export interface NodeData  {
  label: string
  parent: string
  class: string[],
}

// SearchQueryKeys
enum SearchQueryKeys {
  query = 'query',
  length = 'length',
  provider = 'provider',
  year1 ='year1',
  year2 = 'year2',
}

export default new Vuex.Store( {
  modules: {
    error: error,
    proposal: proposal,
    concordance: concordance,
    match: match,
    conflict: conflict,
    provider: provider,
  }
});

