import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './plugins/bootstrap';
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js';
import './plugins/fetch-interceptor';
import 'whatwg-fetch';
import Vuelidate from 'vuelidate'
import VueCytoscape from 'vue-cytoscape';
Vue.use(VueCytoscape)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Vue.use(Vuelidate)
Vue.config.productionTip = false;

// in local dev we don't want to force a login. We will set the auth headers ourself in the fetch-interceptor
let keycloakLogin = 'check-sso'
if (process.env.VUE_APP_KEYCLOAK_ENABLE === "true") {
  keycloakLogin = 'login-required';
}
Vue.use(VueKeycloakJs, {
  init: {
    onLoad: keycloakLogin,
    checkLoginIframe: false,
  },
  config: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: 'metagrid-frontend',
    realm: process.env.VUE_APP_KEYCLOAK_REALM
  },
  onReady: () => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
});
