<template>
    <div class="match">
        <div class="container-fluid">
            <MatchForm/>
        </div>
      <div class="container-fluid" v-show="!loading">
        <ProposalList/>
        <Pagination :get="list" ref="pagination"></Pagination>
      </div>
      <div class="container-fluid d-flex justify-content-center mb-3" v-if="loading">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
</template>

<script>
  import MatchForm from "@/components/MatchForm";
  import ProposalList from "@/components/ProposalList";
  import Pagination from "@/components/Pagination";
  import {Getters} from "@/store/proposal/type"
  import {mapActions, mapMutations, mapGetters} from 'vuex';
  export default {
    name: "Proposal",
    components: { ProposalList, MatchForm, Pagination},
    mounted() {
      // check if new
      if (this.$route.params.id !== 'new') {
        const id = this.$route.params.id;
        this.idMutation(id);
        this.single(id);
        this.$refs.pagination.change(1);
      }
    },
    methods: {
      ...mapActions('match', ['single']),
      ...mapActions('proposal', ['list']),
      ...mapMutations('proposal', {idMutation: 'id'}),
    },
    computed: {
      ...mapGetters('proposal', {loading: Getters.LOADING})
    }
  }
</script>

<style scoped>

</style>
