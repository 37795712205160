<template>
    <div class="row">
        <div class="col-sm-3">
            <b-form-group
                    :id="`group-field-${id}`"
                    label="Select field for matching"
                    :label-for="`field-${id}`">
                <b-select :options="fields" :value="value.field" :id="`field-${id}`" v-on:change="select" :disabled="disabled">
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Please select a field --</b-form-select-option>
                    </template>
                </b-select>
            </b-form-group>
        </div>
        <div class="col-sm-3">
            <b-form-group
                    :id="`group-operator-${id}`"
                    label="Select operator for matching"
                    :label-for="`operator-${id}`">
                <b-select :options="operators" :value="value.operator" :id="`operator-${id}`" @change="change" :disabled="disabled"> </b-select>
            </b-form-group>
        </div>
        <div class="col-sm-6">
            <MatchOptionField :id="id" :type="value.type" v-bind:option1.sync="value.option1" v-bind:option2.sync="value.option2" @change="change" :disabled="disabled"></MatchOptionField>
        </div>
    </div>
</template>

<script>
  import MatchOptionField from "@/components/MatchOptionField";
  export default {
    name: "MatchField",
    components: {MatchOptionField},
    props: {
      /**
       * Current values for models
       */
      value : {
        type: Object,
        required: true,
      },
      /**
       * State of the input/selects
       */
      disabled: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        /**
         * Random number
         */
        id: null,
        /**
         * Possible operators
         */
        operators: [
          {text: "should match", value:"should"},
          {text: "must match", value:"must"},
        ],
        /**
         * All possible fields
         */
        fields: [
            {
              value: 'first_name',
              text: 'first name',
              type: 'string'
            },
            {
              value: 'last_name',
              text: 'last name',
              type: 'string'
            },
            {
              value: 'birth_date',
              text: 'birth date',
              type: 'date'
            },
            {
              value: 'death_date',
              text: 'death date',
              type: 'date'
            },
        ],
        /**
         * Default options for a string
         * option1 = fuzziness
         * option2 = not used
         */
        defaultString : {
          option1: "0",
          option2: "",
        },
        /**
         * Default options for a date
         * option1 = precision of the matching
         * option2 = tolerance of the matching
         */
        defaultDate: {
          option1: 'yyyy',
          option2: '1y',
        },
      }
    },
    created() {
      // generate random id
      this.id = Math.floor(Math.random() * 10000) + 1;
    },
    methods: {
      /**
       * Select a field
       * @param value
       */
      select(value) {
        const type = this.fields.find((f) => f.value === value).type
        // set default options of the types. So we do not have leftovers from the previous selection
        let option;
        switch(type) {
          case 'string':
            option = this.defaultString
            break
          case 'date':
            option = this.defaultDate
            break
        }
        this.$emit("update:value",{
              field: value,
              operator: this.value.operator,
              option1: option.option1,
              option2: option.option2,
              type: type,
            }
        );

      },
      /**
       * bubble changes
       */
      change() {
        this.$emit("update:value",
          {
            field: this.value.field,
            operator: this.value.operator,
            option1: this.value.option1,
            option2: this.value.option2,
            type: this.value.type,
          }
        )
      }
    },
    computed: {
      /**
       * The OptionsField uses object option as a value for model
       */
      option: {
        get() {
          return {
            option1: this.value.option1,
            option2: this.value.option2,
          }
        },
        set(option) {
          this.$emit("update:value",{
                field: this.value.field,
                operator: this.value.operator,
                option1: option.option1,
                option2: option.option2,
                type: this.value.type,
              }
          )
        }
      }
    }
  }
</script>

<style scoped>

</style>
