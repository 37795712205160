export const Actions = {
    LIST: 'list',
    SINGLE: 'single',
};

export const Getters = {
    LIST: 'list',
    LOADING: 'loading',
    SINGLE: 'single',
};

export const Mutations = {
    LIST: 'list',
    START_LOAD: 'startLoad',
    FINISH_LOAD: 'finishLoad',
    SET: 'set',
    HIGHLIGHT: 'highlight',
    MASK: 'mask',
};
