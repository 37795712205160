import vue from 'vue'
import '@/assets/bootstrap.scss'
import 'bootstrap/dist/css/bootstrap.css'
import {
    FormPlugin,
    InputGroupPlugin,
    FormInputPlugin,
    ToastPlugin,
    PaginationPlugin,
    ListGroupPlugin,
    FormSelectPlugin,
    FormGroupPlugin,
    SpinnerPlugin,
    TooltipPlugin,
    BadgePlugin,
    FormCheckboxPlugin,
    OverlayPlugin,
    LayoutPlugin,
    TablePlugin,
    ButtonPlugin,
    ButtonGroupPlugin,
    CardPlugin,
    NavbarPlugin,
    AlertPlugin
} from 'bootstrap-vue'

// load icons
import { BIcon,BIconBox, BIconSearch, BIconShuffle, BIconFilter, BIconPower, BIconBoxArrowUpRight, BIconPencil, BIconArrowBarUp,BIconTrash,BIconX, BIconCheck, BIconGear, BIconPlus, BIconCalculator, BIconPlay } from 'bootstrap-vue'
// icons
vue.component('BIcon', BIcon)
vue.component('BIconSearch', BIconSearch)
vue.component('BIconShuffle', BIconShuffle)
vue.component('BIconFilter', BIconFilter)
vue.component('BIconPower', BIconPower)
vue.component('BIconBoxArrowUpRight', BIconBoxArrowUpRight)
vue.component('BIconPencil', BIconPencil)
vue.component('BIconArrowBarUp', BIconArrowBarUp)
vue.component('BIconTrash', BIconTrash)
vue.component('BIconX', BIconX)
vue.component('BIconCheck', BIconCheck)
vue.component('BIconGear', BIconGear)
vue.component('BIconPlay', BIconPlay)
vue.component('BIconCalculator', BIconCalculator)
vue.component('BIconPlus', BIconPlus)
vue.component('BIconBox', BIconBox)
// components
vue.use(FormPlugin)
vue.use(FormGroupPlugin)
vue.use(SpinnerPlugin)
vue.use(InputGroupPlugin)
vue.use(FormInputPlugin)
vue.use(TablePlugin)
vue.use(ButtonPlugin)
vue.use(ButtonGroupPlugin)
vue.use(CardPlugin)
vue.use(NavbarPlugin)
vue.use(ToastPlugin)
vue.use(PaginationPlugin)
vue.use(ListGroupPlugin)
vue.use(TooltipPlugin)
vue.use(BadgePlugin)
vue.use(FormSelectPlugin)
vue.use(FormCheckboxPlugin)
vue.use(OverlayPlugin)
vue.use(LayoutPlugin)
vue.use(AlertPlugin)
