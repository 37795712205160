<template>
    <div class="edit-form" v-if="list.length > 0" ref="editform">
        <div class="toggle" @click="toggle">
            <b-icon scale="1.5" icon="arrow-bar-up"/>
            <b-badge :variant="hasError ? 'danger': 'secondary'" class="toggle-badge" v-if="toEdit > 0">{{toEdit}}</b-badge>
        </div>
        <div class="edit-list">
            <b-card no-body>
                <template v-slot:header>
                    <div class="action-header">
                        <h6 class="mb-0">Edit concordance #{{list.map(e =>e.id).join(" ")}}</h6>
                        <b-button-group>
                            <b-button @click="preDestroy" :disabled="!canDestroy" size="sm" v-b-tooltip.hover title="Destroy a concordance. Remove relations between the persons">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                            <b-button @click="preSave(list)" :disabled="!canSave" size="sm" v-b-tooltip.hover title="Save edited concordance as shown below.">
                                <b-icon icon="check"></b-icon>
                            </b-button>
                            <b-button @click="preClear" :disabled="!canClear" size="sm" v-b-tooltip.hover title="Cancel editing">
                                <b-icon icon="x"></b-icon>
                            </b-button>
                        </b-button-group>
                    </div>
                </template>

                <b-list-group flush>
                    <template v-for="(concordance, k1) in list">
                        <b-list-group-item v-for="(person, key) in concordance.persons" :key="`${k1}${key}`">
                            <div class="d-flex" :class="person.error ? 'has-error':''">
                                <PersonInfoRow :person="person" class="flex-grow-1"></PersonInfoRow>
                                <div class="action">
                                    <b-button  @click="preRemovePerson(person)" size="sm" v-b-tooltip.hover title="Remove person from concordance">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </div>
                            </div>
                        </b-list-group-item>
                    </template>
                </b-list-group>
            </b-card>
        </div>
    </div>
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex';
  import checks from '../plugins/checks';
  import {Getters} from "@/store/concordance/type";
  import PersonInfoRow from "@/components/PersonInfoRow";
  export default {
    name: "EditForm",
    components: {PersonInfoRow},
    data() {
      return {
        isOpen: false,
      }
    },
    computed: {
      ...mapGetters('concordance', [Getters.EDIT_LIST]),
      /**
       * Return the number of persons in the edit list
       * @return number
       */
      toEdit() {
        return this.list.reduce((a, i) => {
          return a + i.persons.length;
        }, 0)
      },
      /**
       * Check if the form has an error
       * @returns {boolean}
       */
      hasError() {
        return checks.hasError(this.list)
      },
      /**
       * Return a list of checked concordances (we run some validators)
       * @return {ConcordanceValidation[]}
       */
      list() {
        // check the data and validate
        return checks.run(this.editList);
      },
      /**
       * Check if we can destroy a concordance
       * @return {boolean}
       */
      canDestroy() {
        return this.editList.length === 1;
      },
      /**
       * Check if we can save a concordance
       * @return {boolean}
       */
      canSave() {
        return this.editList.length > 0;
      },
      /**
       * Check if we can clear the edit list
       * @return {boolean}
       */
      canClear() {
        return this.editList.length > 0;
      }
    },
    methods: {
      ...mapActions('concordance', ['destroy', 'merge']),
      ...mapMutations('concordance', ['removePersonEditList', 'clearEditList']),
      /**
       * Toggle the state of the edit box
       */
      toggle() {
        if (!this.isOpen) {
          this.setOpenHeight();
        } else {
          document.getElementsByClassName('edit-box')[0].style.top = 100 + '%';
          // handle backdrop animation
          document.body.classList.toggle("edit-box-close");
          setTimeout(() => {
            document.body.classList.toggle("edit-box-close");
          }, 300)
        }
        document.body.classList.toggle("edit-box-open");
        this.isOpen = !this.isOpen;
      },
      /**
       * Dynamically set a height if we change the number of persons in the edit box
       */
      setOpenHeight() {
        const height = this.$refs.editform ? this.$refs.editform.clientHeight : 0;
        const wHeight = window.innerHeight;
        document.getElementsByClassName('edit-box')[0].style.top = wHeight - height + 'px';
      },
      /**
       * Run checks before we save on the store and trigger the action
       */
      preSave() {
        // Run checks and mark result
        if (checks.hasError(checks.run(this.editList))) {
          this.$bvModal.msgBoxOk('Validation error. Check the input for errors', {
            title: 'Can\'t save concordance',
            okVariant: 'warning',
            headerClass: 'p-2',
            footerClass: 'p-2',
            centered: true
          });
          // don't update
          return;
        }
        this.merge();
        setImmediate(this.toggle);
      },
      /**
       * Clear the list and toggle the box
       */
      preClear() {
        this.clearEditList();
        setImmediate(this.toggle)
      },
      /**
       * Remove one person from a editList and toggle height
       * @param person
       */
      preRemovePerson(person) {
        this.removePersonEditList(person);
        if(this.editList.length === 0) {
          setImmediate(this.toggle)
        } else {
          setImmediate(this.setOpenHeight);
        }
      },
      /**
       * Add a guard before we destroy a concordance
       * @return {Promise<void>}
       */
      async preDestroy() {
        // check if we can destroy the concordance
        if (this.editList.length !== 1) {
          return;
        }
        // guard before we destroy concordance
        const destroy = await this.$bvModal.msgBoxConfirm('Please confirm that you want to destroy the selected concordance.', {
          title: 'Please Confirm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (destroy) {
          this.destroy(this.editList[0].id);
          setImmediate(this.toggle);
        }
      }
    }
  }
</script>

<style scoped lang="scss">

    .edit-box-open .toggle {
        svg {
            transform: rotate(180deg);
        }

        &:hover {
            margin-top: -22px;
        }
    }

    .toggle {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -28px;
        width: 80px;
        height: 30px;
        z-index: 101;
        left: 50%;
        transform: translate(-50%, 0);
        box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.75);
        background: #f7f7f7;
        cursor: pointer;
        transition: margin-top 300ms ease-in-out;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        svg {
            transition: transform 300ms ease-in-out;
            transform-origin: center center;
        }

        // this will cover the shadow on the bottom of the toggle. Is a bit hacky
        &::after {
            content: "";
            width: 80px;
            height: 15px;
            bottom: -15px;
            position: absolute;
            background: #f7f7f7;
        }

        &:hover {
            margin-top: -32px;
        }
    }

    .toggle-badge {
        position: absolute;
        right: 5px;
    }

    .edit-list {
        position: relative;
        box-shadow: 0 -5px 20px 0 rgba(128, 128, 128, 1);
        border: 1px solid #ddd;
        z-index: 100;
    }

    .action-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        > div {
            > div {
                display: inline-block;
                padding: 0 5px;
                cursor: pointer;
            }
        }
    }

    .group-row {
        flex-wrap: nowrap;

        .action {
            width: 3%;
            text-align: right;
        }
    }

    .has-error {
        color: var(--red);
    }
</style>
