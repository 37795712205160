<template>
  <b-overlay :show="loading"  spinner-variant="primary"  spinner-type="grow">
    <div class="d-flex justify-content-between mb-4" v-if="!isNew">
            <h3>{{single.name}}</h3>
            <b-button size="sm" @click="showConfig = !showConfig"><b-icon icon="gear"></b-icon></b-button>
        </div>
        <transition name="fade" duration="300" >
            <FormContainer v-if="showConfig">
                <!-- Action slot -->
                <template v-slot:action>
                    <b-row v-if="isNew">
                        <div class="col-sm-3 offset-sm-9 d-flex justify-content-end">
                            <b-button-group>
                                <b-button @click="addField"><b-icon icon="plus"></b-icon> Option</b-button>
                                <b-button @click="preTest"><b-icon icon="calculator"></b-icon> Test</b-button>
                                <b-button @click="preSave"><b-icon icon="play"></b-icon> Match</b-button>
                            </b-button-group>
                        </div>
                    </b-row>
                </template>

                <h3>Configure the matching</h3>
                    <b-form novalidate @submit="preSave">
                        <b-row>
                            <b-col>
                                <b-form-group
                                        id="group-name"
                                        label="Name for the matching"
                                        label-for="name">
                                    <b-input name="name" id="name" v-model="$v.single.name.$model" :disabled="!isNew" required="required"           :state="validateState('name')"
                                    ></b-input>
                                    <b-form-invalid-feedback id="name">The name is a required field.</b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-for="(field, index) in single.parameters" :key="index">
                            <div class="col-11">
                                <MatchField v-bind:value.sync="single.parameters[index]" :disabled="!isNew" :id="`parameter-${index}`" @update:value="validateParameter(index)"/>
                              <div class="invalid-feedback-1" v-show="!$v.single.parameters.$each.$iter[index].field.required">Please select a field.</div>
                            </div>
                            <div class="col-1 d-flex align-items-center justify-content-end" v-if="isNew">
                                <b-button @click="removeField(index)">
                                    <b-icon icon="trash"></b-icon>
                                </b-button>
                            </div>
                        </b-row>
                      <b-row v-show="!$v.single.parameters.required" class="invalid-feedback-1">
                        <div class="col-12">
                          You need to configure at least one matching option.
                        </div>
                      </b-row>
                      <b-row v-show="!$v.single.parameters.maxLength" class="invalid-feedback-1">
                        <div class="col-12">
                          You can just configure 4 matching options. More is not possible.
                        </div>
                      </b-row>
                      <b-row v-show="!$v.single.parameters.uniqueField" class="invalid-feedback-1">
                        <div class="col-12">
                          Please select for each matching option a different field.
                        </div>
                      </b-row>
                    </b-form>
            </FormContainer>
        </transition>

  </b-overlay>
</template>

<script>
  import MatchField from "@/components/MatchField";
  import FormContainer from "@/components/FormContainer";
  import {mapActions, mapGetters} from 'vuex';
  import { required, minLength, maxLength } from 'vuelidate/lib/validators';

  /**
   * Validator to check that each field is selected just once
   * @param value
   * @return {boolean}
   */
  const uniqueField =
      (value) => {
        const fields  = [];
        return !value.some((p) => {
          if(fields.some((f) => f === p.field)){
            return true
          }
          fields.push(p.field);
          return false;
        })
      }
  export default {
    name: "MatchForm",
    components: {MatchField, FormContainer},
    data() {
      return {
        // show configuration
        showConfig: false,
        // is a new match. Its just possible to edit fields for a new matching
        isNew: true,
        // save new match flag
        loading: false,
      }
    },
    validations: {
      single: {
        name: {
          required,
          minLength: minLength(4),
        },
        parameters: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(4),
          uniqueField,
          $each: {
            field: {
              required,
            }
          }
        }
      }
    },
    mounted() {
      // check if new
      if (this.$route.params.id !== 'new') {
        this.isNew = false;
        this.showConfig = false;
      } else {
        this.showConfig = true;
      }
    },
    computed: {
      ...mapGetters('match', ['single']),
    },
    methods: {
      ...mapActions('match', {'new': 'new', 'singleAction': 'single'}),
      ...mapActions('proposal', ['test']),
      /**
       * Pre-save hook. At the end we call save
       **/
      async preSave() {
        // Validate form
        this.$v.single.$touch();
        if (this.$v.single.$anyError) {
          return;
        }

        // set loading flag and send to server
        this.loading = true;
        await this.new({
          name: this.single.name,
          parameters: this.single.parameters,
        });

        // go back to overview
        // wait until ES sync
        setTimeout(() => {
          this.loading = false;
          this.$router.push('/matching');
          }, 1100)
      },
      /**
       * Run a test match
       * Don't use a pagination. This is just to check if we find something
       */
      async preTest() {
        // Validate form
        this.$v.single.$touch();
        if (this.$v.single.$anyError) {
          return;
        }

        await this.test(
          {
            name: this.single.name,
            parameters: this.single.parameters,
          }
        )
      },
      /**
       * Validate a field
       * @param name {string}
       */
      validateState(name) {
        const { $dirty, $error } = this.$v.single[name];
        return $dirty ? !$error : null;
      },

      /**
       * Check nested parameter
       * @param index {number}
       **/
      validateParameter(index) {
        const { $dirty, $error } = this.$v.single.parameters.$each.$iter[index];
        return $dirty ? !$error : null;
      },

      /**
       * Remove single search field
       * @param index
       */
      removeField(index) {
        this.single.parameters.splice(index,1);
      },

      /**
       * Add single search field
       */
      addField() {
          this.single.parameters.push({
            field: null,
            operator: 'must',
            option1: '',
            option2: '',
            type: 'string',
          });
          // init validator for the field
          this.$v.single.parameters.$each.$iter[this.single.parameters.length - 1].$touch()
      },
    }
  }
</script>

<style scoped lang="scss">
    .fade-enter-active,
    .fade-leave-active {
        transition: all 300ms linear;
    }

    .fade-enter-to, .fade-leave {
        opacity: 1;
        max-height: 800px;

    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
        max-height: 0;
    }

    .form-container {
        overflow: hidden;
        backface-visibility: hidden;
    }

    .invalid-feedback-1 {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
      display: block
    }
</style>
