import {Module} from 'vuex';
import {api, MetagridError, Provider} from '@/store';
import {Actions, Getters, Mutations} from '@/store/provider/type';
import {ErrorType} from '@/store/error';

interface ProviderState {
    list: Provider[];
}

const provider: Module<ProviderState, any> = {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        /**
         * Commit the provider list to the store
         * @param state
         * @param data
         */
        [Mutations.LIST](state, data: []) {
            state.list = data;
        },
    },
    getters: {
        [Getters.LIST]: state => {
            return state.list;
        }
    },
    actions: {
        /**
         * Load all providers
         * @param commit
         */
        async [Actions.LIST]({commit, rootState}) {
            const data = await fetch(`${api}/v3/providers`).
                then(r => {
                    if (r.status >= 200 && r.status <= 299) {
                        return r.json();
                    } else {
                        throw Error(r.statusText);
                    }
                }).catch((err) => {
                    const metagridError: MetagridError = {
                        title: "Network error",
                        message: 'Can\'t fetch list of provider. Network error',
                        type: ErrorType.error
                    }
                    commit('error/add', metagridError, { root: true });
                    throw err
                });
            commit(Mutations.LIST, data.providers);
        },
    }
};

export default provider;
