export const Actions = {
    LIST: 'list',
    TEST: 'test',
    ACCEPT: 'accept',
    REJECT: 'reject',
};

export const Getters = {
    LIST: 'list',
    LOADING: 'loading',
};

export const Mutations = {
    LIST: 'list',
    START_LOAD: 'startLoad',
    FINISH_LOAD: 'finishLoad',
    ID: 'id',
    CLEAR: 'clear',
    REMOVE: 'remove',
};
