<template>
  <div class="d-flex justify-content-between group-row">
    <div>{{person.first_name}}</div>
    <div>{{person.last_name}}</div>
    <div>{{person.birth_date}}</div>
    <div>{{person.death_date}}</div>
    <div class="external-link">
      <a :href="person.url"><b-icon-box-arrow-up-right scale="1.5"></b-icon-box-arrow-up-right></a>
    </div>
    <div class="provider">{{person.provider}}</div>
  </div>
</template>

<script>
export default {
  name: "PersonInfoRow",
  props: {
    person: {
      type: Object
    },
  }
}
</script>

<style scoped lang="scss">
  .group-row {
    > div {
      width: 20%;
      text-align: left;
    }

    .external-link {
      width: 1.5rem;
      padding: 0 5px;
    }

    .provider {
      width: 15%;
    }
  }
</style>
