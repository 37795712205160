<template>
    <div class="resultlist">
        <transition-group name="fade" mode="out-in">
            <b-card no-body v-for="(item, index) in list" :key="index" class="mb-4" :id="item.id">
                <template v-slot:header>
                    <div class="action-header">
                        <h6 class="mb-0">Concordance #{{item.id}}</h6>
                        <div @click="preEdit(item)"><b-icon icon="pencil" scale="1.5"></b-icon></div>
                    </div>
                </template>

                <b-list-group flush>
                    <b-list-group-item v-for="(person, key) in item.persons" :key="key">
                        <div class="d-flex">
                          <PersonInfoRow :person="person" class="flex-grow-1"></PersonInfoRow>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
        </transition-group>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    import PersonInfoRow from "@/components/PersonInfoRow";
  export default {
    name: "ResultList",
    computed: {
      ...mapGetters('concordance', ["list"])
    },
    components: {PersonInfoRow},
    methods: {
      ...mapMutations('concordance',['edit']),
      preEdit(item) {
        // hook
        this.edit(item);
      },
    }
  }
</script>

<style scoped lang="scss">
    .action-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        > div {
            padding: 0 5px;
            cursor: pointer;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: all 300ms;
        opacity: 0.5;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
