import {Module} from 'vuex';
import {api, Concordance, MetagridError, Pagination, Person, SearchQuery} from '@/store';
import {Actions, Getters, Mutations} from '@/store/concordance/type';
import {TmpPagination} from '@/store/proposal';
import {ErrorType} from '@/store/error';

// State of the concordance
export interface ConcordanceState {
    search: SearchQuery;
    loading: boolean;
    list: Concordance[];
    editList: Concordance[];
}

// SearchQueryKeys
export enum SearchQueryKeys {
    query = 'query',
    length = 'length',
    provider = 'provider',
    year1 ='year1',
    year2 = 'year2',
}

const concordance: Module<ConcordanceState, any> = {
    namespaced: true,
    state: {
        loading: false,
        search: {
            query: '',
            length: 0,
            provider: '',
            year1: -1,
            year2: -1,
        },
        list: [],
        editList: [],
    },
    getters: {
        /**
         * Get the list of proposals
         * @param state
         */
        [Getters.LIST]: state => {
            return state.list;
        },

        /**
         * Get the state of loading
         * @param state
         */
        [Getters.LOADING]: state => {
            return state.loading;
        },

        /**
         * The edit list of concordance
         * @param state
         */
        [Getters.EDIT_LIST]: state => {
            return state.editList;
        },

    },
    mutations: {
        /**
         * Save search query
         * @param state
         * @param query
         */
        [Mutations.QUERY](state, query: SearchQuery) {
            state.search.query = query.query;
            state.search.length = query.length;
            state.search.provider = query.provider;
            state.search.year1 = query.year1;
            state.search.year2 = query.year2;
        },
        /**
         * Commit list of concordances
         * @param state
         * @param data
         */
        [Mutations.LIST](state, data: []) {
            state.list = data;
        },

        /**
         * init loading
         * @param state
         */
        [Mutations.START_LOAD]: (state) => {
            state.loading = true;
        },

        /**
         * finish loading
         * @param state
         */
        [Mutations.FINISH_LOAD]: (state) => {
            state.loading = false;
        },
        /**
         * Put a concordance into the edit list
         * @param state
         * @param concordance
         */
        [Mutations.EDIT_LIST](state, concordance: Concordance) {
            const index = state.list.findIndex((v: any) => v.id === concordance.id);
            state.list.splice(index, 1);
            state.editList.push(concordance);
        },
        /**
         * Clear edit state
         * @param state
         */
        [Mutations.CLEAR_EDIT_LIST](state) {
            state.editList = [];
        },
        /**
         * Remove a person from the edit array of concordances
         * todo: refactor, this looks too complicated
         * @param state
         * @param person
         */
        [Mutations.REMOVE_PERSON_EDIT_LIST](state, person: Person) {
            const editData = state.editList;
            for (let i = editData.length - 1; i >= 0; i -= 1) {
                const persons = editData[i].persons;
                for (let x = persons.length - 1; x >= 0; x -= 1) {
                    if(persons[x] === person) {
                        persons.splice(x, 1);
                    }
                }

                // remove the concordance if we removed all the persons
                if(persons.length === 0) {
                    editData.splice(i, 1);
                } else {
                    editData[i].persons = persons;
                }
            }
            state.editList = editData;
        },

        /**
         * Insert a concordance to the beginning of the list
         * @param state
         * @param concordance
         */
        [Mutations.APPEND](state, concordance: Concordance) {
            state.list.unshift(concordance)
        },

        /**
         * Update or insert a concordance in the list
         * @param state
         * @param concordance
         */
        [Mutations.UPDATE](state, concordance: Concordance) {
            const index = state.list.findIndex(c => c.id === concordance.id);
            if(index > -1) {
                state.list[index] = concordance;
            } else {
                state.list.push(concordance);
            }
        }
    },
    actions: {
        /**
         * Search over rest api
         * @param commit
         * @param pagination
         * @param state
         */
        async [Actions.LIST]({ commit, state, rootState }, pagination: TmpPagination) {
            commit(Mutations.START_LOAD);
            if(typeof pagination === "undefined") {
                pagination = {
                    size: 20,
                    from: 0,
                };
            }
            // save query in store
            const params: string[] = [];
            Object.keys(state.search).forEach((k ) => {
                const key = k as keyof typeof SearchQueryKeys;
                params.push(`${k}=${state.search[key]}`);
            });
            params.push(`from=${pagination.from}`);
            params.push(`size=${pagination.size}`);
            const data = await fetch(`${api}/v3/concordances/search?${params.join('&')}`).
            then(r => {
                if (r.status >= 200 && r.status <= 299) {
                    return r.json();
                } else {
                    throw Error(r.statusText);
                }
            }).catch((error) => {
                const metagridError: MetagridError = {
                    title: "Network error",
                    message: 'Can\'t fetch list of concordances from server.',
                    type: ErrorType.error
                }
                commit('error/add', metagridError, { root: true });
                throw error;
            });
            commit(Mutations.LIST, data.concordances);
            commit(Mutations.FINISH_LOAD);
            return data.meta.total;
        },

        /**
         * Search over the api. shorhand for the query mutation and the list action
         * @param commit
         * @param dispatch
         * @param query
         */
        async [Actions.SEARCH]({commit, dispatch}, query: {search: SearchQuery; pagination: Pagination}) {
            commit(Mutations.QUERY, query.search);
            dispatch(Actions.LIST, query.pagination);
        },

        /**
         * Destroy a concordance
         * @param commit
         * @param id
         */
        async [Actions.DESTROY]({commit}, id: string) {
            await fetch(`${api}/v3/concordances/${id}`,
                {method: 'DELETE' }).then(r => {
                if (r.status >= 200 && r.status <= 299) {
                    return r.json();
                } else {
                    throw Error(r.statusText);
                }
            }).catch((err) => {
                const metagridError: MetagridError = {
                    title: "Network error",
                    message: 'Can\'t delete concordances.',
                    type: ErrorType.error
                }
                commit('error/add', metagridError, { root: true });
                throw err;
            });
            commit(Mutations.CLEAR_EDIT_LIST)
        },

        /**
         * Update and merge a set of concordances
         * @param commit
         * @param state
         */
        async [Actions.MERGE]({commit, state}) {
            const concordance = await fetch(`${api}/v3/concordances/updateMerge`,
                {
                    method: 'POST',
                    body: JSON.stringify({concordances: state.editList}),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                })
                .then(r => {
                    if (r.status >= 200 && r.status <= 299) {
                        return r.json();
                    } else {
                        throw Error(r.statusText);
                    }
                }).catch((err) => {
                    // clear list
                    // hint: this will clear the proposal edit form
                    commit(Mutations.CLEAR_EDIT_LIST);
                    // throw error
                    const metagridError: MetagridError = {
                        title: "Network error",
                        message: 'Can\'t merge concordances.',
                        type: ErrorType.error
                    }
                    commit('error/add', metagridError, { root: true });
                    throw err;
                });
            commit(Mutations.CLEAR_EDIT_LIST);
            commit(Mutations.APPEND, concordance)
        },

        async [Actions.UPDATE]({commit}, concordance: Concordance) {
            const con = await fetch(`${api}/v3/concordances/${concordance.id}`,
                {method: 'PUT',
                    body: JSON.stringify({concordance: concordance}),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                }).then(r =>  {
                    if (r.status >= 200 && r.status <= 299) {
                        return r.json();
                    } else {
                        throw Error(r.statusText);
                    }
                }).catch((err) => {
                    const metagridError: MetagridError = {
                        title: "Network error",
                        message: 'Can\'t update concordance.',
                        type: ErrorType.error
                    }
                    commit('error/add', metagridError, { root: true });
                    throw err;
                });
            commit(Mutations.UPDATE, con);
        }
    }
};

export default concordance
