export const Actions = {
    SEARCH: 'search',
    LIST: 'list',
    DESTROY: 'destroy',
    MERGE: 'merge',
    UPDATE: 'update',
};

export const Getters = {
    LIST: 'list',
    LOADING: 'loading',
    EDIT_LIST: 'editList',
};

export const Mutations = {
    LIST: 'list',
    APPEND: 'append',
    UPDATE: 'update',
    QUERY: 'query',
    START_LOAD: 'startLoad',
    FINISH_LOAD: 'finishLoad',
    EDIT_LIST: 'edit',
    CLEAR_EDIT_LIST: 'clearEditList',
    REMOVE_PERSON_EDIT_LIST: 'removePersonEditList',
};
