<template>
    <div>
        <div v-if="type === 'string'">
            <b-form-group
                    :id="`group-option-${id}`"
                    :label="`Fuzziness ${option1}`"
                    :label-for="`option-${id}`">
                <b-form-input :id="`option-${id}`" :value="option1" type="range" min="0" max="5" @change="change1" :disabled="disabled"></b-form-input>
            </b-form-group>
        </div>

        <div v-if="type === 'date'" class="d-flex justify-content-between">
            <b-form-group
                    class="col-sm-6"
                    :id="`group-precision-${id}`"
                    label="Select precision for dates"
                    :label-for="`precision-${id}`">
                <b-select :options="precisions" :value="option1" :id="`operator-${id}`" @change="change1" :disabled="disabled">
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Please select a precision --</b-form-select-option>
                    </template>
                </b-select>
            </b-form-group>
            <b-form-group
                    class="col-sm-6"
                    :id="`group-tolerance-${id}`"
                    :label="`Tolerance for dates`"
                    :label-for="`tolerance-${id}`">
                <b-select :options="tolerances" :value="option2" :id="`tolerance-${id}`" @change="change2" :disabled="disabled">
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Please select a tolerance --</b-form-select-option>
                    </template>
                </b-select>
            </b-form-group>
        </div>
    </div>
</template>

<script>
  export default {
    name: "MatchOptionField",
    props: {
      type: {
        type: String,
        default: 'string'
      },
      id: {
        type: Number,
        default: () => Math.floor(Math.random() * 10000) + 1
      },
      option1: {
        type: String
      },
      option2: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        /**
         * possible precisions
         */
        precisions: [
          {value: 'yyyy', text: 'year'},
          {value: 'yyyy-MM', text: 'year month'},
          {value: 'yyyy-MM-dd', text: 'year month day'},
        ]
      }
    },
    methods: {
      /**
       * bubble changes
       */
      change1(value) {
        this.$emit('update:option1', value)
        this.$emit('update:option2', null)
      },
      /**
       * bubble changes
       */
      change2(value) {
        this.$emit('update:option2', value)
      },
    },
    computed: {
      /**
       * possible tolerances
       * @return {({text: string, value: string}|{text: string, value: string}|{text: string, value: string}|{text: string, value: string}|{text: string, value: string})[]}
       */
      tolerances() {
        if(this.option1 === 'yyyy') {
          return [
            {value: '1y', text: '+-1 year'},
            {value: '10y', text: '+-10 years'},
            {value: '20y', text: '+-20 years'},
            {value: '50y', text: '+-50 years'},
            {value: '100y', text: '+-100 years'},
          ]
        } else if(this.option1 === 'yyyy-MM') {
          return [
            {value: '1M', text: '+-1 month'},
            {value: '2M', text: '+-2 months'},
            {value: '3M', text: '+-3 months'},
            {value: '6M', text: '+-6 months'},
            {value: '9M', text: '+-9 month'},
          ]
        }
        // for days
        return [
          {value: '1d', text: '+-1 day'},
          {value: '2d', text: '+-2 days'},
          {value: '3d', text: '+-3 days'},
          {value: '7d', text: '+-7 days'},
          {value: '14d', text: '+-14 days'},
          {value: '21d', text: '+-21 days'},
        ];
      }
    }
  }
</script>

<style scoped>

</style>
