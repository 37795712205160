import fetchIntercept from 'fetch-intercept';
import Vue from 'vue';

fetchIntercept.register({
    request: function (url, config) {
        // check config object
        if (typeof config === 'undefined') {
            config = {};
        }
        // check headers object
        if (config.headers === null || typeof config.headers === 'undefined') {
            config.headers = {};
        }

        // in dev environment we want to not leverage keycloak. We hardcode the gatekeeper headers
        if (process.env.VUE_APP_KEYCLOAK_ENABLE === "true") {
            const token = Vue.prototype.$keycloak.token
            config.headers['Authorization'] = `Bearer ${token}`;
        } else {
            config.headers['X-Auth-Username'] = process.env.VUE_APP_TEST_USER;
            config.headers['X-Auth-Provider'] = process.env.VUE_APP_TEST_PROVIDER;
            config.headers['X-Auth-Roles'] = process.env.VUE_APP_TEST_ROLES;
        }
        return [url, config];
    },
});
