<template>
    <FormContainer>
    <b-form novalidate @submit="preSearch" class="searchform">
        <div class="row mb-4">
            <div class="col-md-6">
                <label class="sr-only" for="query">Search query</label>
                <b-input
                        name="query"
                        id="query"
                        placeholder="..."
                        v-model="query"
                ></b-input>
            </div>
            <div class="col-sm-6">
                <b-button type="submit" class="mr-4" id="search-btn"><b-icon scale="1.2" icon="search"/>Suchen</b-button>
                <b-button @click="toggleFilter" :pressed="showFilter" id="filter-btn"><b-icon scale="1.2" icon="filter"/>Filter</b-button>
            </div>
        </div>
        <transition name="fade">
            <div class="row row-filter" :class="showFilter? 'show-filter':''">
                <div class="col-md-3">
                    <b-form-group
                            id="group-provider"
                            label="Select Provider"
                            label-for="provider">
                        <b-select :options="providerOptions" v-model="provider" id="provider"></b-select>
                    </b-form-group>
                </div>
                <div class="col-md-3">
                    <b-form-group
                            id="group-years"
                            class=""
                            label="Years"
                            label-for="provider">
                        <div class="d-flex justify-content-between align-items-center">
                            <b-input v-model="year1" type="number" class="year" id="year1"></b-input> to  <b-input v-model="year2" type="number" class="year" id="year2"></b-input></div>
                    </b-form-group>
                </div>
                <div class="col-md-3">
                    <b-form-group
                            id="group-length"
                            :label="`min ${length} persons in concordance`"
                            label-for="length">
                        <b-form-input id="length" v-model="length" type="range" min="1" max="11"></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </transition>
    </b-form>
    </FormContainer>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import FormContainer from "@/components/FormContainer";
  export default {
    name: "SearchForm",
    components: {FormContainer},
    data() {
      return {
        query: '',
        showFilter: false,
        provider: "",
        length: 1,
        year1: null,
        year2: null,
      }
    },
    computed: {
      ...mapGetters('provider', {providerList: 'list'}),
      providerOptions() {
        const list =  this.providerList.map((i) => {
          const text = i.provider_details.find((pd) => pd.fallback).name;
          return {value: i.slug, text};
        });
        // add a default option
        list.unshift({value:"", text:"Select provider"});
        return list;
      }
    },
    methods: {
      ...mapMutations("concordance", {'queryMutation': 'query'}),
      preSearch(e) {
        e.preventDefault();
        const query = {
          query: this.query,
          provider: this.provider,
          length: this.length,
          year1: this.year1 === null? -1 : this.year1,
          year2: this.year2 === null? -1 : this.year2,
        };
        // set query in store
        this.queryMutation(query);
        this.$emit("search", query);
      },
      toggleFilter() {
        this.showFilter = !this.showFilter;
      }
    }
  }
</script>

<style scoped lang="scss">
    input {
        width: 100%;
    }

    .year {
        width: 40% !important;
    }

    .row-filter {
        transition: max-height 300ms ease-in-out;
        max-height: 0;
        overflow: hidden;

        &.show-filter {
        max-height: 90px;
         }
    }

</style>
