<template>
    <div class="match">
        <div class="container-fluid mb-4">
            <div class="row">
                <div class="col-12 d-flex justify-content-end">
                    <b-button @click="preNewMatch">New</b-button>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <MatchList/>
            <Pagination :get="list" ref="pagination"></Pagination>
        </div>
    </div>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'
  import MatchList from "@/components/MatchList";
  import Pagination from "@/components/Pagination";
  export default {
    name: "MatchOverview",
    components: {MatchList, Pagination},
    mounted() {
      this.$refs.pagination.change();
    },
    methods: {
      ...mapMutations('match', ['new']),
      ...mapMutations('proposal', ['clear']),
      ...mapActions('match', ['list']),
      preNewMatch() {
        this.clear();
        this.new();
        this.$router.push('matching/new');
      }
    }
  }
</script>

<style scoped>

</style>
