<template>
    <div>
        <b-table
            id="my-table"
            :busy.sync="loading"
            :items="list"
            :fields="fields" >
            <template v-slot:cell(id)="id">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <RouterLink :to="`matching/${id.value}`">{{id.value}}</RouterLink>
            </template>
            <template v-slot:cell(created_at)="created_at">
                <!-- `data.value` is the value after formatted by the Formatter -->
                {{created_at.value | date}}
            </template>
        </b-table>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: "MatchList",
    data () {
      return {
        fields:  [
          {
            key: 'id',
            sortable: false
          },
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'found',
            sortable: true
          },
          {
            key: 'status',
            sortable: true
          },
          {
            key: 'created_at',
            sortable: true
          },
        ]
      }
    },
    computed: {
      ...mapGetters('match', ['list', 'loading'])
    },
    filters: {
      date: function(d) {
          return d.slice(0, 10)
        }
    }
  }
</script>

<style scoped>

</style>
