<template>
    <b-pagination v-model="current"
                  :total-rows="total"
                  :per-page="size"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                  @change="change"
    v-if="total > size">
    </b-pagination>
</template>

<script>
  export default {
    name: "Pagination",
    props: {
      get: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        total: 0,
        size: 20,
        current: 1,
      }
    },
    methods: {
      // trigger the callback function
      async change(value) {
        // if we trigger the function from an external component
        if (typeof value === "undefined") {
          value = this.current;
        } else {
          this.current = value;
        }
        const from = (value - 1) * this.size;
        this.total = await this.get({from, size: this.size})
      }
    }
  }
</script>

<style scoped>

</style>
